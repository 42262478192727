.container{
  background-image: url('./assets/images/bg.png');
  background-size: cover;
}


.font-face-gtmblack {
  font-family: "GothamBlack";
}

.font-face-gtmbold {
  font-family: "GothamBold";
}

.font-face-gtmbook {
  font-family: "GothamBook";
}

.font-face-gtmbookitalic {
  font-family: "GothamBookItalic";
}

.font-face-gtmlight {
  font-family: "GothamLight";
}

.font-face-gtmthin {
  font-family: "GothamThin";
}

.font-face-gtmthinitalic {
  font-family: "GothamThinItalic";
}

.font-face-gtmultraItalic {
  font-family: "GothamUltraItalic";
}

.font-face-gtmxlight {
  font-family: "GothamXLight";
}

.font-face-gtmxlightitalic {
  font-family: "GothamXLightItalic";
}

